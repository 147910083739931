<template>
  <div class="tfoms__params">
    <div class="tfoms__params-header">Параметры</div>

    <div class="tfoms__params-wrap">
      <div class="mb-3">
        ПЕРИОД: <span class="primary--text">{{ year }} год</span>
      </div>

      <m-select
        :items="optionsHalfYear"
        v-model="selectHalfYear"
        placeholder="полугодие"
        @input="unselectAll('selectHalfYear')"
        itemValue="id"
        hide-details
      ></m-select>
      <m-select
        :items="optionsQuarter"
        v-model="selectQuarter"
        placeholder="квартал"
        @input="unselectAll('selectQuarter')"
        itemValue="id"
        hide-details
      ></m-select>
      <m-select
        :items="optionsMonth"
        v-model="selectMonth"
        placeholder="месяц"
        @input="unselectAll('selectMonth')"
        itemValue="id"
        hide-details
      ></m-select>

      <div style="margin-top: 35px" class="mb-5">
        УСЛОВИЯ И ВИДЫ ОКАЗАНИЯ МП
      </div>
      <div class="tfoms__params-levels">
        <m-select
          v-model="level1"
          :items="healthCares"
          item-text="Name"
          placeholder="Условия и виды МП (Уровень 1)"
          hide-details
          :clearable="false"
        ></m-select>
        <m-select
          v-model="level2"
          :items="level2Optons"
          item-text="Name"
          placeholder="Условия и виды МП (Уровень 2)"
          hide-details
          @click:clear="clearLevel(2)"
        ></m-select>
        <m-select
          v-model="level3"
          :items="level3Optons"
          item-text="Name"
          placeholder="Условия и виды МП (Уровень 3)"
          hide-details
          @click:clear="clearLevel(3)"
        ></m-select>
        <m-select
          v-model="level4"
          :items="level4Optons"
          item-text="Name"
          placeholder="Условия и виды МП (Уровень 4)"
          hide-details
          @click:clear="clearLevel(4)"
        ></m-select>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import TFormService from "@/services/TFormService";
import tfomsTab from "../mixins/tfomsTab";
import CreateAndEditMixin from "@/mixins/CreateAndEditMixin.js";
import DataHelper from "../../../../utils/DataHelper";

export default {
  components: {
    mSelect: () => import("@/components/base/inputs/mSelect"),
  },
  mixins: [CreateAndEditMixin, tfomsTab],
  props: {
    value: {
      type: Number,
      default: 1,
    },
    currentCare: {
      type: Object,
      default: () => {},
    },
    healthCares: {
      type: Array,
      default: null,
    },
    tfomsMore: {
      type: Object,
      default: null,
    },
    year: {
      type: Number,
      default: 2024,
    },
  },
  data() {
    return {
      loading: false,
      startLoading: 0,
      endLoading: 0,
      level1: this.currentCare?.Id,
      level2: null,
      level3: null,
      level4: null,
      level2Optons: [],
      level3Optons: [],
      level4Optons: [],
      data: null,
      params: {},
      groupId: this.currentCare?.Id,
      selectHalfYear: null,
      selectQuarter: null,
      selectMonth: null,
      optionsHalfYear: [
        { id: 1, value: "Первое полугодие" },
        { id: 2, value: "Второе полугодие" },
      ],
      optionsQuarter: [
        { id: 1, value: "1-ый квартал" },
        { id: 2, value: "2-ой квартал" },
        { id: 3, value: "3-ий квартал" },
        { id: 4, value: "4-ый квартал" },
      ],
      optionsMonth: [
        { id: 1, value: "Январь" },
        { id: 2, value: "Февраль" },
        { id: 3, value: "Март" },
        { id: 4, value: "Апрель" },
        { id: 5, value: "Май" },
        { id: 6, value: "Июнь" },
        { id: 7, value: "Июль" },
        { id: 8, value: "Август" },
        { id: 9, value: "Сентябрь" },
        { id: 10, value: "Октябрь" },
        { id: 11, value: "Ноябрь" },
        { id: 12, value: "Декабрь" },
      ],
    };
  },
  computed: {
    textSelectPeriod() {
      if (this.selectHalfYear) {
        return this.optionsHalfYear.find((e) => e.id === this.selectHalfYear)
          .value;
      } else if (this.selectQuarter) {
        return this.optionsQuarter.find((e) => e.id === this.selectQuarter)
          .value;
      } else if (this.selectMonth) {
        return this.optionsMonth.find((e) => e.id === this.selectMonth).value;
      }

      return "";
    },
    lastActive() {
      let lastActive = 0;
      for (let i = 4; i >= 1; i--) {
        if (this["level" + i]) {
          lastActive = i;
          break;
        }
      }
      return lastActive;
    },
  },
  watch: {
    currentCare(val) {
      if (val) this.level1 = val.Id;
    },
    selectHalfYear() {
      this.update();
      this.init();
    },
    selectQuarter() {
      this.update();
      this.init();
    },
    selectMonth() {
      this.update();
      this.init();
    },

    level1(val) {
      if (!val) return;
      this.updateGroup(val, 1);
      this.resetLevels(1);
    },
    level2(val) {
      if (!val) return;
      this.updateGroup(val, 2);
      this.resetLevels(2);
    },
    level3(val) {
      if (!val) return;
      this.updateGroup(val, 3);
      this.resetLevels(3);
    },
    level4(val) {
      if (!val) return;
      this.updateGroup(val, 4);
      this.resetLevels(4);
    },
    "tfomsMore.value"() {
      this.update();
      this.init();
    },
  },
  async mounted() {
    this.update();
    await this.init(1);
  },
  methods: {
    getLabels() {
      const ret = [];
      for (let i = 1; i <= 4; i++) {
        const a = this["level" + i];
        if (a) {
          let n = "";
          if (i === 1) {
            n = this.healthCares.find((e) => e.Id === a);
          } else {
            n = this["level" + i + "Optons"]?.find((e) => e.Id === a);
          }
          ret.push(n);
        }
      }
      return ret;
    },
    setNextLevel(level) {
      switch (level) {
        case 1:
          this.level2Optons = this.data?.Children;
          break;
        case 2:
          this.level3Optons = this.data?.Children;
          break;
        case 3:
          this.level4Optons = this.data?.Children;
          break;
      }
    },
    resetLevels(curLevel) {
      for (let i = curLevel + 1; i <= 4; i++) {
        this["level" + i + "Optons"] = [];
        this["level" + i] = null;
      }
    },
    detailedNormalize(data) {
      data.VolumeTotalPlanValue = this.getUnit(data.VolumeTotalPlanValue);
      data.VolumeTotalValue = this.getUnit(data.VolumeTotalValue);

      data.FinanceTotalPlanValue = this.getRub(data.FinanceTotalPlanValue);
      data.FinanceTotalValue = this.getRub(data.FinanceTotalValue);
      data.NonPaymentValue = this.getRub(data.NonPaymentValue);
      data.SanctionTotalValue = this.getRub(data.SanctionTotalValue);

      for (let i = 0; i < data.Children.length; i++) {
        const item = data.Children[i];
        item.VolumeValue = this.getUnit(item.VolumeValue);
        item.VolumePlanValue = this.getUnit(item.VolumePlanValue);
        item.FinancialPlanValue = this.getRub(item.FinancialPlanValue);
        item.FinancialValue = this.getRub(item.FinancialValue);

        item.NonPaymentValue = this.getRub(item.NonPaymentValue);
        item.FineValue = this.getRub(item.FineValue);
        item.SanctionValue = this.getRub(item.SanctionValue);
      }

      if (data.SanctionValues)
        for (let i = 0; i < data.SanctionValues.length; i++) {
          const item = data.SanctionValues[i];
          item.FineValue = this.getRub(item.FineValue);
          item.FineVolumeValue = this.getUnit(item.FineVolumeValue);
          item.NonPaymentValue = this.getRub(item.NonPaymentValue);
          item.NonPaymentVolumeValue = this.getUnit(item.NonPaymentVolumeValue);
          item.SanctionValue = this.getRub(item.SanctionValue);
        }
      if (data.ReasonRefusalPayDatas) {
        DataHelper.treeListTraverse(data.ReasonRefusalPayDatas, (item) => {
          item.FineValue = this.getRub(item.FineValue);
          item.NonPaymentValue = this.getRub(item.NonPaymentValue);
        });
      }

      DataHelper.treeListTraverse(data.Divisions, (e) => {
        e.FinancialValue = this.getRub(e.FinancialValue);
        e.FineValue = this.getRub(e.FineValue);
        e.NonPaymentValue = this.getRub(e.NonPaymentValue);
        e.VolumeValue = this.getUnit(e.VolumeValue);
      });

      if (data.VolumeValues)
        for (let i = 0; i < data.VolumeValues.length; i++) {
          const item = data.VolumeValues[i];
          item.VolumeValue = this.getUnit(item.VolumeValue);
          item.VolumePlanValue = this.getUnit(item.VolumePlanValue);
          item.FineVolumeValue = this.getUnit(item.FineVolumeValue);
          item.NonPaymentVolumeValue = this.getUnit(item.NonPaymentVolumeValue);
        }

      return data;
    },
    init: debounce(async function (level) {
      this.loading = true;
      this.startLoading++;
      try {
        this.data = this.detailedNormalize(
          (
            await TFormService.tfoms1.getTFOMSDetailedInfo({
              ...this.params,
              ...this.queryParams?.params,
            })
          ).data
        );
      } catch (error) {
        console.log(error);
      }
      this.setNextLevel(level || this.lastActive);

      if (this.tfomsMore) {
        this.tfomsMore.setData(this.data);
        this.tfomsMore.setLabels(this.getLabels());
      }
      this.loading = false;
      this.endLoading++;
    }, 500),
    async updateGroup(val, level) {
      this.inputGroupId(val);
      await this.init(level);
    },
    inputGroupId(val) {
      this.groupId = val;
      this.update();
    },
    update() {
      this.params = {
        halfYear: this.selectHalfYear,
        quarter: this.selectQuarter,
        month: this.selectMonth,
        groupId: this.groupId,
        section: this.tfomsMore?.value ? this.tfomsMore?.value + 1 : 1,
      };

      this.$emit("updateParams", {
        ...this.queryParams?.params,
        ...this.params,
      });
    },
    unselectAll(except) {
      if (except !== "selectHalfYear") this.selectHalfYear = null;
      if (except !== "selectQuarter") this.selectQuarter = null;
      if (except !== "selectMonth") this.selectMonth = null;

      this.update();
    },

    // Установка занчения на любом уровне
    setLevel(val) {
      for (let i = 1; i <= 4; i++) {
        if (val.Id === this["level" + i]) {
          this["level" + i] = null;
          this.$nextTick(() => {
            this["level" + i] = val.Id;
          });
        }
      }

      if (this.lastActive > 0 && this.lastActive <= 3) {
        this["level" + (this.lastActive + 1)] = val.id;
      }
      // Сбросить код
      this.setSelectCode({ Id: null });
    },
    setLastLevel(val) {
      if (this.lastActive > 0 && this.lastActive <= 3) {
        this["level" + (this.lastActive + 1)] = val.id;
      }
    },
    setSelectCode(val) {
      this.$emit("updatedetailParams", {
        reasonId: val.Id,
      });
    },
    clearLevel(val) {
      if (val > 1) {
        this.setLevel({ Id: this["level" + (val - 1)] });
      }
    },
  },
};
</script>
<style lang="scss">
.tfoms__params {
  width: 330px;
  height: 709px;
  background-color: var(--v-white-base);
  border-radius: 10px;
  font-size: 14px;
  color: var(--v-blue-grey-base);

  &-levels {
    input {
      font-size: 14px;
    }
  }

  .v-input {
    padding-top: 0;
  }
  .v-select__selection {
    color: var(--v-primary-base);
    margin-bottom: 0;
    font-size: 14px;
  }

  &-wrap {
    padding: 18px 15px 18px 20px;
  }

  &-header {
    height: 78px;
    background-color: var(--v-blue-grey-lighten2);
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .v-input--radio-group {
    padding: 0 20px 20px 20px;
  }
  .v-list-item:not(:last-child) {
    border-bottom: solid 1px var(--v-grey-base);
  }
}
</style>
